<template>
	<div class="page">
		<h2 class="is-title is-inverted">Business Card Briefcase</h2>
		<div class="page-background"></div>
		<div class="page-description">
			Business cards for booth <strong>{{ booth.title }}</strong>
		</div>
		<div class="container card-exchange">
			<div class="margin-auto level w50">
				<div class="level-item">
					<button class="button is-primary" @click.prevent="exportCsv">Export to CSV</button>
				</div>
				<div class="level-item">
					<label class="checkbox">
						<input v-model="hideProcessed" type="checkbox" />
						Hide processed
					</label>
				</div>
			</div>
			<table class="table margin-auto">
				<thead>
					<tr>
						<th>Date &darr;</th>
						<th>e-mail</th>
						<th>Message</th>
						<th>Processed?</th>
					</tr>
				</thead>
				<tbody>
					<tr v-for="card in shownCards" :key="card.business_card_id">
						<td>{{ card.created_at | systemDateTimeFormat }}</td>
						<td>{{ card.email }}</td>
						<td>{{ card.message }}</td>
						<td>
							<template v-if="!card.processed"><button class="button is-primary is-small" @click="processCard(card)">Processed</button></template>
							<template v-else>
								<inline-svg :src="require('../assets/check.svg')" width="25" />
							</template>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
</template>

<script>
	import $http from '../utils/http';

	function exportToCsv(filename, rows) {
		var processRow = function(row) {
			var finalVal = '';
			for (var j = 0; j < row.length; j++) {
				var innerValue = row[j] === null ? '' : row[j].toString();
				if (row[j] instanceof Date) {
					innerValue = row[j].toLocaleString();
				}
				var result = innerValue.replace(/"/g, '""');
				if (result.search(/("|,|\n)/g) >= 0) result = '"' + result + '"';
				if (j > 0) finalVal += ',';
				finalVal += result;
			}
			return finalVal + '\n';
		};

		var csvFile = '';
		for (var i = 0; i < rows.length; i++) {
			csvFile += processRow(rows[i]);
		}

		var blob = new Blob([csvFile], { type: 'text/csv;charset=utf-8;' });
		if (navigator.msSaveBlob) {
			// IE 10+
			navigator.msSaveBlob(blob, filename);
		} else {
			var link = document.createElement('a');
			if (link.download !== undefined) {
				var url = URL.createObjectURL(blob);
				link.setAttribute('href', url);
				link.setAttribute('download', filename);
				link.style.visibility = 'hidden';
				document.body.appendChild(link);
				link.click();
				document.body.removeChild(link);
			}
		}
	}

	export default {
		name: 'CardExchange',
		data() {
			return {
				cards: [],
				booth: {},
				hideProcessed: false,
			};
		},
		beforeRouteEnter(to, from, next) {
			$http.get(`/business-card-exchange/${to.params.url}`).then(
				data => {
					$http.get(`/booths/${to.params.url}`).then(booth => {
						next(vm => {
							vm.setData(data.data);
							vm.setBooth(booth.data);
						});
					});
				},
				() => {
					next('/');
				}
			);
		},
		beforeRouteUpdate(to, from, next) {
			$http.get(`/business-card-exchange/${to.params.url}`).then(
				data => {
					this.setData(data.data);

					$http.get(`/booths/${to.params.url}`).then(booth => {
						this.setBooth(booth.data);
						next();
					});
				},
				() => {
					next('/');
				}
			);
		},
		computed: {
			shownCards() {
				return this.hideProcessed ? this.cards.filter(e => !e.processed) : this.cards;
			},
		},
		methods: {
			setData(data) {
				this.cards = data.sort((a, b) => (new Date(a.created_at) > new Date(b.created_at) ? -1 : new Date(a.created_at) < new Date(b.created_at) ? 1 : 0));
			},
			setBooth(booth) {
				this.booth = booth;
			},
			processCard(card) {
				$http.put(`/business-card-process/${card.business_card_id}`).then(() => {
					card.processed = true;
				});
			},
			exportCsv() {
				let data = [];
				data.push(['date', 'username', 'email', 'message', 'processed']);
				for (let card of this.shownCards) {
					data.push([card.created_at, card.username, card.email, card.message, card.processed ? 'yes' : 'no']);
				}
				exportToCsv('export.csv', data);
			},
		},
	};
</script>

<style scoped></style>
